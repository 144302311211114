import axios, { AxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';
// config
import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: HOST_API,
});

export const refreshToken = async () =>
  axios
    .get(`${HOST_API}${endpoints.auth.refresh}`, {
      withCredentials: true,
    })
    .then((response) => {
      console.log('response', response);
      const accessToken = Cookies.get('token');
      if (!accessToken) {
        Cookies.remove('token');
        Cookies.remove('refreshToken');

        delete axios.defaults.headers.common.Authorization;
        window.location.replace('/');
      }
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
      return true;
    })
    .catch((error) => {
      Cookies.remove('token');
      Cookies.remove('refreshToken');

      delete axios.defaults.headers.common.Authorization;
      window.location.replace('/');
    });

// Interceptor per le richieste
axiosInstance.interceptors.request.use(
  async (config) => {
    console.log('config', config);
    if (!Cookies.get('token') && config.url?.split('/')[1] !== 'auth') {
      const a = await refreshToken();
      console.log('a', a);
    }

    const accessToken = Cookies.get('token');
    config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
  },
  (error) => {
    if (error.response && error.response.data && error.response.data.message === 'Invalid token') {
      Cookies.remove('token');
      Cookies.remove('refreshToken');

      delete axios.defaults.headers.common.Authorization;
      window.location.replace('/');
    }
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];
  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  protected: {
    documents: {
      list: '/protected/documents/list',
      reserved: '/protected/documents/list/reserverd',
    },
    document: {
      upload: '/protected/document/upload',
      edit: '/protected/document/edit',
      reserved: '/protected/document/reserved',
      get: (documentId: string) => `/protected/document/${documentId}`,
      delete: (documentId: string) => `/protected/document/${documentId}`,
    },
    notices: {
      list: '/protected/notices/list',
      listLimit: (limit: number) => `/protected/notices/list/${limit}`,
    },
    notice: {
      upload: '/protected/notice/upload',
      edit: '/protected/notice/edit',
      get: (noticeId: string) => `/protected/notice/${noticeId}`,
      delete: (noticeId: string) => `/protected/notice/${noticeId}`,
    },
    guarantees: {
      list: '/protected/guarantees/list',
    },
    guarantee: {
      create: '/protected/guarantee/create',
    },
  },
  auth: {
    me: '/auth/me',
    refresh: '/auth/refresh',
    login: '/auth/login',
    register: '/api/auth/register',
  },
  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  post: {
    list: '/api/post/list',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search',
  },
  product: {
    list: '/api/product/list',
    details: '/api/product/details',
    search: '/api/product/search',
  },
};
