import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

const IndexPage = lazy(() => import('src/pages/dashboard/home'));
// LEGNO
const LegnoPage = lazy(() => import('src/pages/dashboard/legno'));
const NovaPage = lazy(() => import('src/pages/dashboard/legno/finestre/nova'));
const ThertmicxPage = lazy(() => import('src/pages/dashboard/legno/finestre/thermicx'));
const SatinPage = lazy(() => import('src/pages/dashboard/legno/finestre/satin'));
const ThermicIngressoPage = lazy(() => import('src/pages/dashboard/legno/ingressi/thermic'));
const AlzanteLegnoPage = lazy(() => import('src/pages/dashboard/legno/alzanti/legno'));
const ComplanariLegnoPage = lazy(() => import('src/pages/dashboard/legno/complanari/legno'));
const CassonettoLegnoPage = lazy(() => import('src/pages/dashboard/legno/cassonetti/legno'));
// PVC
const PvcPage = lazy(() => import('src/pages/dashboard/pvc'));
const IconPage = lazy(() => import('src/pages/dashboard/pvc/finestre/icon'));
const Linea76Page = lazy(() => import('src/pages/dashboard/pvc/finestre/linea76'));
const Vision76Page = lazy(() => import('src/pages/dashboard/pvc/finestre/vision76'));
const SolarisPage = lazy(() => import('src/pages/dashboard/pvc/finestre/solaris'));
const IsoltekPage = lazy(() => import('src/pages/dashboard/pvc/finestre/isoltek'));
const ComplanaroPVCPage = lazy(() => import('src/pages/dashboard/pvc/complanari/pvc'));
const MeridianaPage = lazy(() => import('src/pages/dashboard/pvc/alzanti/meridiana'));
const CassonettiPVCPage = lazy(() => import('src/pages/dashboard/pvc/cassonetti/pvc'));
const BattistaPage = lazy(() => import('src/pages/dashboard/pvc/portoncini/battista'));
const FarnesePage = lazy(() => import('src/pages/dashboard/pvc/portoncini/farnese'));
// ALLUMINIO
const AlluminioPage = lazy(() => import('src/pages/dashboard/alluminio'));
const MetropolisPage = lazy(() => import('src/pages/dashboard/alluminio/finestre/metropolis'));
const FormaPage = lazy(() => import('src/pages/dashboard/alluminio/finestre/forma'));
const MetropolisIngressoPage = lazy(
  () => import('src/pages/dashboard/alluminio/ingressi/metropolis')
);
const ComplanariAlluminioPage = lazy(
  () => import('src/pages/dashboard/alluminio/complanari/alluminio')
);
const AlzanteAlluminioPage = lazy(() => import('src/pages/dashboard/alluminio/alzanti/alluminio'));
const AlzanteFormaPage = lazy(() => import('src/pages/dashboard/alluminio/alzanti/forma'));
const RaffaelloPage = lazy(() => import('src/pages/dashboard/alluminio/oscuranti/raffaello'));
const LeonardoPage = lazy(() => import('src/pages/dashboard/alluminio/oscuranti/leonardo'));
const PicassoPage = lazy(() => import('src/pages/dashboard/alluminio/oscuranti/picasso'));
const GiottoPage = lazy(() => import('src/pages/dashboard/alluminio/oscuranti/giotto'));
const CassonettiAlluminioPage = lazy(
  () => import('src/pages/dashboard/alluminio/cassonetti/alluminio')
);
// TAVOLE TECNICHE
const TavoleTecnichePage = lazy(() => import('src/pages/dashboard/tavole-tecniche'));
// DOCUMENTI
const DocumentiPage = lazy(() => import('src/pages/dashboard/documenti'));
// COMUNICAZIONI
const ComunicazioniPage = lazy(() => import('src/pages/dashboard/comunicazioni'));
// GARANZIE
const GaranziePage = lazy(() => import('src/pages/dashboard/garanzie'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      {
        path: 'legno',
        children: [
          { element: <LegnoPage />, index: true },
          {
            path: 'finestre',
            children: [
              { element: <LegnoPage />, index: true },
              { path: 'nova', element: <NovaPage /> },
              { path: 'thermicx', element: <ThertmicxPage /> },
              { path: 'satin', element: <SatinPage /> },
            ],
          },
          {
            path: 'ingressi',
            children: [
              { element: <LegnoPage />, index: true },
              { path: 'thermic', element: <ThermicIngressoPage /> },
            ],
          },
          {
            path: 'alzanti',
            children: [
              { element: <LegnoPage />, index: true },
              { path: 'legno', element: <AlzanteLegnoPage /> },
            ],
          },
          {
            path: 'complanari',
            children: [
              { element: <LegnoPage />, index: true },
              { path: 'legno', element: <ComplanariLegnoPage /> },
            ],
          },
          {
            path: 'cassonetti',
            children: [
              { element: <LegnoPage />, index: true },
              { path: 'legno', element: <CassonettoLegnoPage /> },
            ],
          },
        ],
      },
      {
        path: 'pvc',
        children: [
          { element: <PvcPage />, index: true },
          {
            path: 'finestre',
            children: [
              { element: <PvcPage />, index: true },
              { path: 'icon', element: <IconPage /> },
              { path: 'linea76', element: <Linea76Page /> },
              { path: 'vision76', element: <Vision76Page /> },
              { path: 'solaris', element: <SolarisPage /> },
              { path: 'isoltek', element: <IsoltekPage /> },
            ],
          },
          {
            path: 'complanari',
            children: [
              { element: <PvcPage />, index: true },
              { path: 'pvc', element: <ComplanaroPVCPage /> },
            ],
          },
          {
            path: 'alzanti',
            children: [
              { element: <PvcPage />, index: true },
              { path: 'meridiana', element: <MeridianaPage /> },
            ],
          },
          {
            path: 'portoncini',
            children: [
              { element: <PvcPage />, index: true },
              { path: 'battista', element: <BattistaPage /> },
              { path: 'farnese', element: <FarnesePage /> },
            ],
          },
          {
            path: 'cassonetti',
            children: [
              { element: <PvcPage />, index: true },
              { path: 'pvc', element: <CassonettiPVCPage /> },
            ],
          },
        ],
      },
      {
        path: 'alluminio',
        children: [
          { element: <AlluminioPage />, index: true },
          {
            path: 'finestre',
            children: [
              { element: <AlluminioPage />, index: true },
              { path: 'metropolis', element: <MetropolisPage /> },
              { path: 'forma', element: <FormaPage /> },
            ],
          },
          {
            path: 'ingressi',
            children: [
              { element: <AlluminioPage />, index: true },
              { path: 'metropolis', element: <MetropolisIngressoPage /> },
            ],
          },
          {
            path: 'complanari',
            children: [
              { element: <AlluminioPage />, index: true },
              { path: 'alluminio', element: <ComplanariAlluminioPage /> },
            ],
          },
          {
            path: 'alzanti',
            children: [
              { element: <AlluminioPage />, index: true },
              { path: 'alluminio', element: <AlzanteAlluminioPage /> },
              { path: 'forma', element: <AlzanteFormaPage /> },
            ],
          },
          {
            path: 'oscuranti',
            children: [
              { element: <AlluminioPage />, index: true },
              { path: 'raffaello', element: <RaffaelloPage /> },
              { path: 'leonardo', element: <LeonardoPage /> },
              { path: 'picasso', element: <PicassoPage /> },
              { path: 'giotto', element: <GiottoPage /> },
            ],
          },
          {
            path: 'cassonetti',
            children: [
              { element: <AlluminioPage />, index: true },
              { path: 'alluminio', element: <CassonettiAlluminioPage /> },
            ],
          },
        ],
      },
      {
        path: 'tavoleTecniche',
        children: [{ element: <TavoleTecnichePage />, index: true }],
      },
      {
        path: 'documenti',
        children: [{ element: <DocumentiPage />, index: true }],
      },
      {
        path: 'comunicazioni',
        children: [{ element: <ComunicazioniPage />, index: true }],
      },
      {
        path: 'garanzie',
        children: [{ element: <GaranziePage />, index: true }],
      },
    ],
  },
];
