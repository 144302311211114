import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';

export function useNavData() {
  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: 'Prodotti',
        items: [
          {
            title: 'legno',
            path: paths.dashboard.legno.root,
          },
          {
            title: 'PVC',
            path: paths.dashboard.pvc.root,
          },
          {
            title: 'alluminio',
            path: paths.dashboard.alluminio.root,
          },
          {
            title: 'tavole tecniche',
            path: paths.dashboard.tavoleTecniche.root,
          },
        ],
      },

      // MANAGEMENT
      // ----------------------------------------------------------------------
      {
        subheader: 'Documenti',
        items: [
          { title: 'comunicazioni', path: paths.dashboard.comunicazioni.root },
          { title: 'documenti', path: paths.dashboard.documenti.root },
          {
            title: 'marketing',
            path: 'https://drive.google.com/drive/folders/1OO02tVpd3xgKi5o3iySb2W-YqmiId1T6?usp=sharing',
          },
          {
            title: 'garanzie',
            path: paths.dashboard.garanzie.root,
          },
        ],
      },
    ],
    []
  );

  return data;
}
