// routes
import { paths } from 'src/routes/paths';

// API
// ----------------------------------------------------------------------

export const HOST_API =
  process.env.REACT_APP_HOST_DOCKER === 'False'
    ? 'https://api.professional.fossatiserramenti.it'
    : 'http://localhost:3000';
export const ASSETS_API = 'https://api-dev-minimal-v510.vercel.app';

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = paths.dashboard.root; // as '/dashboard'
