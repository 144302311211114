// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    legno: {
      root: `${ROOTS.DASHBOARD}/legno`,
      finestre: {
        root: `${ROOTS.DASHBOARD}/legno/finestre`,
        nova: `${ROOTS.DASHBOARD}/legno/finestre/nova`,
        thermicx: `${ROOTS.DASHBOARD}/legno/finestre/thermicx`,
        satin: `${ROOTS.DASHBOARD}/legno/finestre/satin`,
      },
      ingressi: {
        root: `${ROOTS.DASHBOARD}/legno/ingressi`,
        thermic: `${ROOTS.DASHBOARD}/legno/ingressi/thermic`,
      },
      alzanti: {
        root: `${ROOTS.DASHBOARD}/legno/alzanti`,
        legno: `${ROOTS.DASHBOARD}/legno/alzanti/legno`,
      },
      complanari: {
        root: `${ROOTS.DASHBOARD}/legno/complanari`,
        legno: `${ROOTS.DASHBOARD}/legno/complanari/legno`,
      },
      cassonetti: {
        root: `${ROOTS.DASHBOARD}/legno/cassonetti`,
        legno: `${ROOTS.DASHBOARD}/legno/cassonetti/legno`,
      },
    },
    alluminio: {
      root: `${ROOTS.DASHBOARD}/alluminio`,
      finestre: {
        root: `${ROOTS.DASHBOARD}/alluminio/finestre`,
        metropolis: `${ROOTS.DASHBOARD}/alluminio/finestre/metropolis`,
        forma: `${ROOTS.DASHBOARD}/alluminio/finestre/forma`,
      },
      alzanti: {
        root: `${ROOTS.DASHBOARD}/alluminio/alzanti`,
        alluminio: `${ROOTS.DASHBOARD}/alluminio/alzanti/alluminio`,
        forma: `${ROOTS.DASHBOARD}/alluminio/alzanti/forma`,
      },
      complanari: {
        root: `${ROOTS.DASHBOARD}/alluminio/complanari`,
        alluminio: `${ROOTS.DASHBOARD}/alluminio/complanari/alluminio`,
      },
      ingressi: {
        root: `${ROOTS.DASHBOARD}/alluminio/ingressi`,
        metropolis: `${ROOTS.DASHBOARD}/alluminio/ingressi/metropolis`,
      },
      oscuranti: {
        root: `${ROOTS.DASHBOARD}/alluminio/oscuranti`,
        raffaello: `${ROOTS.DASHBOARD}/alluminio/oscuranti/raffaello`,
        leonardo: `${ROOTS.DASHBOARD}/alluminio/oscuranti/leonardo`,
        picasso: `${ROOTS.DASHBOARD}/alluminio/oscuranti/picasso`,
        giotto: `${ROOTS.DASHBOARD}/alluminio/oscuranti/giotto`,
      },
      cassonetti: {
        root: `${ROOTS.DASHBOARD}/alluminio/cassonetti`,
        alluminio: `${ROOTS.DASHBOARD}/alluminio/cassonetti/alluminio`,
      },
    },
    pvc: {
      root: `${ROOTS.DASHBOARD}/pvc`,
      finestre: {
        root: `${ROOTS.DASHBOARD}/pvc/finestre`,
        icon: `${ROOTS.DASHBOARD}/pvc/finestre/icon`,
        linea76: `${ROOTS.DASHBOARD}/pvc/finestre/linea76`,
        vision76: `${ROOTS.DASHBOARD}/pvc/finestre/vision76`,
        solaris: `${ROOTS.DASHBOARD}/pvc/finestre/solaris`,
        isoltek: `${ROOTS.DASHBOARD}/pvc/finestre/isoltek`,
      },
      complanari: {
        root: `${ROOTS.DASHBOARD}/pvc/complanari`,
        pvc: `${ROOTS.DASHBOARD}/pvc/complanari/pvc`,
      },
      alzanti: {
        root: `${ROOTS.DASHBOARD}/pvc/alzanti`,
        meridiana: `${ROOTS.DASHBOARD}/pvc/alzanti/meridiana`,
      },
      portoncini: {
        root: `${ROOTS.DASHBOARD}/pvc/portoncini`,
        battista: `${ROOTS.DASHBOARD}/pvc/portoncini/battista`,
        farnese: `${ROOTS.DASHBOARD}/pvc/portoncini/farnese`,
      },
      cassonetti: {
        root: `${ROOTS.DASHBOARD}/pvc/cassonetti`,
        pvc: `${ROOTS.DASHBOARD}/pvc/cassonetti/pvc`,
      },
    },
    tavoleTecniche: {
      root: `${ROOTS.DASHBOARD}/tavoleTecniche`,
    },
    comunicazioni: {
      root: `${ROOTS.DASHBOARD}/comunicazioni`,
    },
    documenti: {
      root: `${ROOTS.DASHBOARD}/documenti`,
    },
    garanzie: {
      root: `${ROOTS.DASHBOARD}/garanzie`,
    },
  },
};
